<template>
  <div>
    <!-- <Navigation showBack />
    <v-main>
      <v-container> -->
        <!-- <v-row> -->
        <!-- <v-col cols="12"> -->
        <div class="mx-auto" style="max-width:500px">
          <div v-show="step == 1">
            <v-card>
              <v-card-title>Create your FeePlus Account</v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        v-model="firstname"
                        :rules="nameRules"
                        label="First name"
                        hint="Given name"
                        required
                        persistent-hint
                        outlined
                        dense
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="lastname"
                        :rules="nameRules"
                        label="Last name"
                        hint="Surname"
                        required
                        persistent-hint
                        outlined
                        dense
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        outlined
                        dense
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex">
                      <v-text-field
                        v-model="password"
                        label="Password"
                        :type="showPW ? 'text' : 'password'"
                        :rules="pwRules"
                        required
                        outlined
                        dense
                        validate-on-blur
                      ></v-text-field>
                      <v-text-field
                        v-model="confirm"
                        label="Confirm"
                        :type="showPW ? 'text' : 'password'"
                        :rules="pwRules"
                        required
                        outlined
                        dense
                        class="mx-4"
                        validate-on-blur
                      ></v-text-field>
                      <v-btn icon @click="showPW = !showPW"
                        ><v-icon>{{
                          showPW ? "mdi-eye" : "mdi-eye-off"
                        }}</v-icon></v-btn
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        v-model="showCalendar"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthDate"
                            label="Date of birth"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="birthDate"
                          @input="showCalendar = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="sex"
                        :items="gender"
                        label="Gender"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="signUp()">Sign Up</v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <v-slide-x-reverse-transition>
            <div v-show="step == 2">
              <v-card v-if="verified">
                <div class="text-h5 pa-2">
                  <v-icon color="success" large
                    >mdi-check-circle-outline</v-icon
                  >
                  Your FeePlus Account Has Been Verified
                </div>
                <v-card-text class="text-body-1">
                  <p>
                    Key in your password and sign in now to continue using FeePlus.
                  </p>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <div class="text-h5 pa-2">
                  <v-icon color="success" large
                    >mdi-check-circle-outline</v-icon
                  >
                  Your FeePlus Account Has Been Created
                </div>
                <v-card-text class="text-body-1">
                  <p>
                    You are just one step away to be able to sign in with your
                    newly created account.
                  </p>
                  <p>
                    A verification email has sent to
                    <strong>{{ this.email }}</strong
                    >. Please verify your email by clicking the link in the
                    verification email.
                  </p>
                  <p>
                    Please check your <strong>spam folder</strong> if you can't
                    see the email in your inbox.
                  </p>
                  <p>
                    Once you have verify your email, come back here to sign in.
                  </p>
                  <v-divider></v-divider>
                  <p class="mt-4">If you have any problem clicking on the link in your email, you can key in the verification code below:</p>
                  <div class="vcode d-flex">
                    <v-spacer></v-spacer>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup.native="vcodeInput"
                    hide-details
                    id="vcode1"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup="vcodeInput"
                    hide-details
                    id="vcode2"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup="vcodeInput"
                    hide-details
                    id="vcode3"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup="vcodeInput"
                    hide-details
                    id="vcode4"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup="vcodeInput"
                    hide-details
                    id="vcode5"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    maxlength="1"
                    @keyup="vcodeInput"
                    hide-details
                    id="vcode6"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  </div>
                  <div class="d-flex mt-4">
                    <v-btn color="primary" class="mx-auto" @click="verifyEmail">Verify Email</v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <sign-in class="mt-4" @success="signedIn" ref="signInComponent"></sign-in>
            </div>
          </v-slide-x-reverse-transition>

          <!-- </v-col> -->
          <!-- </v-row> -->
        </div>
      <!-- </v-container>
    </v-main> -->
  </div>
</template>
<script>
// import Navigation from "@/components/Navigation";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      step: 1,
      firstname: "",
      lastname: "",
      nameRules: [(v) => !!v || "Name is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      confirm: "",
      showPW: false,
      pwRules: [(v) => !!v || "Password is required"],
      birthDate: "",
      showCalendar: false,
      sex: "",
      gender: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
        { text: "Other", value: "O" },
      ],
      verified: false
    };
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async signUp() {
      if (this.$refs.form.validate()) {
        // check password
        if (this.password !== this.confirm) {
          Swal.fire({
            icon: "error",
            text: "Confirm password is not match. Please re-enter again.",
          });
          this.password = "";
          this.confirm = "";
          return;
        } else if (this.password == "") {
          Swal.fire({
            icon: "error",
            text: "Password cannot be empty",
          });
          return;
        }
        let res = await this.$store.dispatch({
          type: "userSignUp",
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          birthdate: this.birthdate,
          sex: this.sex,
        });
        if (res.error == false) {
          this.step = 2;
          this.$refs.signInComponent.email = this.email
        } else {
          Swal.fire({
            icon: "error",
            text: res.message,
          });
        }
      }
    },
    signedIn() {
      this.$router.push({
        name: "HomeMain",
      });
    },
    vcodeInput(e) { //vcode1
      let n = parseInt(e.target.id.substring(5))
      if(n !== 6){
        document.getElementById('vcode'+(n+1)).focus()
      }
    },
    verifyEmail() {
      // get vcode
      let code = '';
      for(let i = 1; i < 7; i++){
        code += document.getElementById('vcode'+i).value
      }
      if(code.length < 6){
        Swal.fire({
          icon: "error",
          text: "Please fill up all 6 verification codes"
        })
      }
      this.$store.dispatch({
        type: 'userVerifyEmail',
        email: this.email,
        code: code
      }).then(res => {
        if(res.error == false){
          this.verified = true
          //this.$refs.signInComponent.email = this.email
          this.$refs.signInComponent.$refs.passwordInput.focus()
        }
      })
    }
  },
  components: {
    // Navigation,
    signIn: () =>
      import(/* webpackChunkName: "signIn" */ "@/components/SignIn"),
  },
};
</script>
<style scoped>
.vcode .v-input {
  max-width: 33px;
  margin: 0 5px;
}
</style>